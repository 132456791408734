import { createApp } from 'vue'
import {createRouter, createWebHashHistory} from 'vue-router'
import App from './App.vue'

createApp(App)
    .use(createRouter({
        history: createWebHashHistory(),
        routes: [
            { path: '/', component: () => import('./components/MD.vue') },
            { path: '/edit', component: () => import('./components/PostsEditor.vue')}
        ]
    }))
    .mount('#app')
